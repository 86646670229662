import router from '@/router/index';
import apiClient, { loginUser, listContracts, changePassword, getNews, forgotPassword, tokenRefresh, listService, listVehicles, listVignettes, listMilages, setMilage, requestServices, listReminders, requestMaintenance, requestClosingOffer, requestNewVehicleOffer }
    from '@/services/EventService.js';
import store from '..';

const state = {
    axiosWorking: localStorage.getItem('axiosWorking'),
    token: "",
    link: "",
    visibleBalance: localStorage.getItem('isBalanceGranted'),
    generatedPass: '',
    lastLoginTime: '',
    user: {
        email: '',
        password: ''
    },
    userPartnerName: localStorage.getItem('userPartnerName'),
    userType: localStorage.getItem('userType'),
    userName: localStorage.getItem('userName'),
    vehicleListVehicles: [],
    listContractsList: [],
    administrationVehicles: [],
    contractsVehicles: [],
    reportsVehicles: [],
    vehicle: {
        KGFB: 'KGFB Biztosítás',
        CASCO: 'CASCO Biztosítás'
    },
    vignettes: [],
    milages: [],
    serviceList: [],
    reminders: [],
    newsList: [],

};

const getters = {
    isLoggedIn: state => !!state.token,
    isBalanceGranted: state => state.visibleBalance,
    getVehicleById: state => id => {
        return state.vehicles.find(vehicle => vehicle.id === id)
    }
};

const actions = {
    async login({ commit }, user) {
        // commit('SET_USER_EMAIL', user.email)
        // state.user.email = user.email


        await loginUser({
            user: {
                loginName: user.loginName,
                password: user.password
            }
        }).then((response) => {


            state.vehicleListVehicles = [];
            state.administrationVehicles = [];
            state.contractsVehicles = [];
            state.reportsVehicles = [];
            state.reminders = [],
                state.listContractsList = []
            commit('SET_USER_DATA', response.data)

        })


    },
    async changePassword({ commit }, user) {
        await changePassword({
            user: {
                currentpassword: user.currentpassword,
                password: user.password
            }
        }).then((response) => {
            commit('SET_SUCCESS', response.data.success)

        })
    },
    async forgotPassword({ commit }, user) {
        await forgotPassword({
            user: {
                loginName: user.loginName
            }
        }).then((response) => {

            commit('SET_SUCCESS', response.data.success)

        })
    },
    async tokenRefresh({ commit }) {
        apiClient.defaults.headers.common['refreshToken'] = localStorage.getItem('refreshToken');
        await tokenRefresh({
        }).then((response) => {

            commit('SET_TOKEN', response.data.customerToken)
            localStorage.setItem('token', response.data.customerToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            delete apiClient.defaults.headers.common['refreshToken']

        }).catch(error => {
            if (error) {
                commit('CLEAR_USER_DATA');
            }
        });
    },
    // isLoggedIn: ({ commit }) => {
    //     if (localStorage.getItem('token') != null) {
    //         // commit('SET_TOKEN', localStorage.getItem('token'));
    //         state.token = localStorage.getItem('token');
    //         state.user.email = localStorage.getItem('user')
    //         commit('SET_LOGGEDIN', true);

    //         /* axiosConfig.defaults.headers.common['Authorization'] = response.data.token; */
    //     } else {
    //         commit('SET_LOGGEDIN', false);
    //         localStorage.removeItem('token');
    //         /* localStorage.removeItem('refreshToken') */
    //     }
    // },
    // async logout({ commit }) {
    //     await logout({
    //     }).then((response) => {
    //         commit('SET_SUCCESS', response.data.success)
    //         if (state.success) {
    //             localStorage.removeItem('token');
    //             localStorage.removeItem('refreshToken')
    //             delete apiClient.defaults.headers.common['customerToken']
    //             state.token = ''
    //             state.loggedIn = false;
    //             localStorage.removeItem('active-menu');
    //             router.push('/');
    //             state.user.email = '';
    //             state.user.password = '';
    //         }
    //         console.log(state.success);
    //     })
    // },
    logout({ commit }) {
        //console.log(apiClient.CancelToken.cancel('Operation canceled by the user.'));
        // apiClient.CancelToken.cancel('Operation canceled by the user.')
        // console.log(apiClient.CancelToken);

        commit('CLEAR_USER_DATA');



    },

    async listContractsList({ commit }, payload) {

        if (payload.page == 0 && Object.keys(state.listContractsList).length > 0) {
            return
        }
        await listContracts({
            payload: {
                page: payload.page
            }
        }).then((response) => {
            commit('SET_CONTRACTS', response.data.servicePriceList);
            if (response.data.servicePriceList.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listContractsList', {
                    page: payload.page + 1,
                });
            }
        })
    },

    async listVehiclesVehicleList({ commit }, payload) {

        if (payload.page == 0 && Object.keys(state.vehicleListVehicles).length > 0) {
            return
        }
        await listVehicles({
            payload: {
                page: payload.page
            }
        }).then((response) => {
            commit('SET_VEHICLES1', response.data.vehicleList);
            if (response.data.vehicleList.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listVehiclesVehicleList', {
                    page: payload.page + 1,
                });
            }
        })
    },
    async listVehiclesAdministration({ commit }, payload) {
        if (payload.page == 0 && Object.keys(state.administrationVehicles).length > 0) {
            return
        }
        await listVehicles({
            payload: {
                page: payload.page
            }
        }).then((response) => {
            commit('SET_VEHICLES2', response.data.vehicleList);
            if (response.data.vehicleList.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listVehiclesAdministration', {
                    page: payload.page + 1,
                });
            }
        })
    },
    async listVehiclesContracts({ commit }, payload) {
        if (payload.page == 0 && Object.keys(state.contractsVehicles).length > 0) {
            return
        }
        await listVehicles({
            payload: {
                page: payload.page
            }
        }).then((response) => {
            commit('SET_VEHICLES3', response.data.vehicleList);
            if (response.data.vehicleList.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listVehiclesContracts', {
                    page: payload.page + 1,
                });
            }
        })
    },
    async listVehiclesReports({ commit }, payload) {
        if (payload.page == 0 && Object.keys(state.reportsVehicles).length > 0) {
            return
        }
        await listVehicles({
            payload: {
                page: payload.page
            }
        }).then((response) => {
            commit('SET_VEHICLES4', response.data.vehicleList);
            if (response.data.vehicleList.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listVehiclesReports', {
                    page: payload.page + 1,
                });
            }
        })
    },
    async getVehicle({ commit }, vehicle) {
        await listVehicles({
            vehicle: {
                id: vehicle.id
            }
        }).then((response) => {
            commit('SET_VEHICLE', response.data.vehicle)
        })
    },
    async listVignettes({ commit }) {
        await listVignettes({
        }).then((response) => {
            commit('SET_VIGNETTES', response.data.vignetteList)
        })
    },
    async listMilages({ commit }) {
        await listMilages({
        }).then((response) => {
            commit('SET_MILAGES', response.data.milageList)
        })
    },
    async setMilage({ commit }, vehicle) {

        await setMilage({
            vehicle: {
                id: vehicle.id,
                milage: vehicle.milage,
                date: vehicle.date
            }
        }).then((response) => {
            commit('SET_SUCCESS', response.data.success)

        })
    },
    async listServices({ commit }, vehicle) {
        await listService({
            vehicle: {
                id: vehicle.id
            }
        }).then((response) => {

            commit('SET_SERVICES', response.data.serviceList)
        })
    },
    async requestServices({ commit, dispatch }, vehicle) {

        await requestServices({
            vehicle: {
                id: vehicle.vehicleId,
                services: {
                    on: [{ type: vehicle.orderedServiceType }],
                },
            }

        }).then((response) => {

            commit('SET_SUCCESS', response.data.success)
        })

        const tempId = vehicle.vehicleId
        dispatch('listServices', { id: tempId });
    },
    async listReminders({ commit }, payload) {
        if (payload.page == 0 && Object.keys(state.reminders).length > 0) {
            return
        }
        await listReminders({
            payload: {
                page: payload.page
            }
        }).then((response) => {

            commit('SET_REMINDERS', response.data.reminders)
            if (response.data.reminders.length > 0 && state.axiosWorking == 1) {
                store.dispatch('listReminders', {
                    page: payload.page + 1,
                });
            }
        })
    },
    async requestMaintenance({ commit }, vehicle) {
        await requestMaintenance({
            vehicle: {
                id: vehicle.id,
                maintenances: vehicle.maintenances
            }
        }).then((response) => {
            commit('SET_SUCCESS', response.data.success)
        })
    },
    async requestClosingOffer({ commit }, vehicle) {
        await requestClosingOffer({
            vehicle: {
                id: vehicle.id,
                closing: {
                    date: vehicle.closing.date,
                    customer: vehicle.closing.customer,
                    newOffer: vehicle.closing.newOffer
                }
            }
        }).then((response) => {
            commit('SET_SUCCESS', response.data.success)

        })
    },
    async requestNewVehicleOffer({ commit }, newVehicle) {
        await requestNewVehicleOffer({
            newVehicle: {
                brand: newVehicle.brand,
                model: newVehicle.model,
                engine: newVehicle.engine,
                equipment: newVehicle.equipment,
                paymentPeriod: newVehicle.paymentPeriod,
                yearlyMileage: newVehicle.yearlyMileage
            }
        }).then((response) => {
            commit('SET_SUCCESS', response.data.success)

        })
    },

    async getNewsList({ commit }) {
        await getNews({}).then((response) => {

            commit('SET_NEWS', response.data.newsList)
        })
    },
};

const mutations = {
    SET_USER_DATA(state, userdata) {
        if (userdata.visibleBalance === true) {
            state.visibleBalance = true
            localStorage.setItem('isBalanceGranted', userdata.visibleBalance);
        }
        state.axiosWorking = 1
        state.link = userdata.formLink
        state.token = userdata.customerToken
        state.userName = userdata.userName
        state.userType = userdata.userType
        state.user.email = userdata.userEmail;

        state.generatedPass = userdata.generatedPass
        state.lastLoginTime = userdata.lastLoginTime
        state.userPartnerName = userdata.userPartnerName

        localStorage.setItem('token', userdata.customerToken);
        localStorage.setItem('refreshToken', userdata.refreshToken);
        localStorage.setItem('link', userdata.formLink);
        localStorage.setItem('userName', userdata.userName);
        localStorage.setItem('userEmail', userdata.userEmail);
        localStorage.setItem('userType', userdata.userType);
        localStorage.setItem('userPartnerName', userdata.userPartnerName);
        localStorage.setItem('axiosWorking', 1);

        apiClient.defaults.headers.common['customerToken'] = userdata.customerToken;
        // delete apiClient.defaults.headers.common['refreshToken']
    },

    CLEAR_USER_DATA(state) {

        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('LS_ROUTE_KEY')
        localStorage.removeItem('active-menu')
        localStorage.removeItem('link')
        localStorage.removeItem('isBalanceGranted')
        localStorage.removeItem('userName')
        localStorage.removeItem('userType')
        localStorage.removeItem('userPartnerName')
        localStorage.removeItem('axiosWorking');
        delete apiClient.defaults.headers.common['customerToken']
        delete apiClient.defaults.headers.common['refreshToken']
        state.axiosWorking = 0,
            state.token = ''
        state.user.email = '';
        state.user.password = '';
        state.vehicleListVehicles = [];
        state.administrationVehicles = [];
        state.contractsVehicles = [];
        state.reportsVehicles = [];
        state.reminders = [],
            state.visibleBalance = false

        state.generatedPass = ''
        state.lastLoginTime = ''
        state.userPartnerName = ''
        state.userName = ''
        state.userType = ''

        router.push({ name: 'Login' });

    },
    SET_TOKEN: (state, token) => {
        state.token = token;
        apiClient.defaults.headers.common['customerToken'] = token;
    },

    SET_LINK: (state, link) => {
        state.link = link;

    },

    SET_BALANCE: (state, balance) => {
        state.visibleBalance = balance;

    },

    SET_USER_EMAIL: (state, email) => {
        state.user.email = email;
        localStorage.setItem('userEmail', email);
    },
    SET_SUCCESS: (state, success) => {
        state.success = success
    },

    SET_VEHICLES1: (state, vehicles) => {
        state.vehicleListVehicles = state.vehicleListVehicles.concat(vehicles)
    },
    SET_VEHICLES2: (state, vehicles) => {
        state.administrationVehicles = state.administrationVehicles.concat(vehicles)
    },
    SET_VEHICLES3: (state, vehicles) => {
        state.contractsVehicles = state.contractsVehicles.concat(vehicles)
    },
    SET_VEHICLES4: (state, vehicles) => {
        state.reportsVehicles = state.reportsVehicles.concat(vehicles)
    },

    SET_CONTRACTS: (state, vehicles) => {
        state.listContractsList = state.listContractsList.concat(vehicles)
    },

    SET_COMPONENT_NAME: (state, payload) => {

        state.componentName = payload

        //state.vehicles = vehicles
    },
    SET_VEHICLE: (state, vehicle) => {
        state.vehicle = vehicle
    },

    SET_VIGNETTES: (state, vignettes) => {
        state.vignettes = vignettes
    },
    SET_MILAGES: (state, milages) => {
        state.milages = milages
    },
    SET_SERVICES: (state, services) => {

        state.serviceList = services
    },
    SET_REMINDERS: (state, reminders) => {

        state.reminders = state.reminders.concat(reminders)
    },

    SET_NEWS: (state, news) => {

        state.newsList = news
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

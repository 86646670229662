import axios from 'axios'
import store from '../store';




const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'apiToken': process.env.VUE_APP_API_TOKEN
    },

})



// apiClient.CancelToken = axios.CancelToken.source();

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.response.request.responseURL.indexOf("tokenRefresh") == -1 && error.response.data.status == 403) {
            store.dispatch('tokenRefresh')
        }
        return Promise.reject(error)
    });

const getStatus = () => {
    return apiClient.get('/status')
};

const loginUser = (user) => {
    return apiClient.post('/login', user)
};

const changePassword = (user) => {
    return apiClient.post('/changePassword', user)
};

const forgotPassword = (user) => {
    return apiClient.post('/forgotPassword', user)
};

const tokenRefresh = () => {
    return apiClient.post('/tokenRefresh')
};

const logout = () => {
    return apiClient.post('/logout')
};

const listVehicles = (payload) => {
    return apiClient.post('/vehicleList', payload)
};

const listContracts = (payload) => {
    return apiClient.post('/servicePriceList', payload)
};

const getVehicle = (vehicle) => {
    return apiClient.post('/vehicle', vehicle)
};

const listVignettes = (vehicle) => {
    return apiClient.post('/vignetteList', vehicle)
};

const listMilages = (vehicle) => {
    return apiClient.post('/milageList', vehicle)
};

const setMilage = (vehicle) => {
    return apiClient.post('/milage', vehicle)
};

const listService = (vehicle) => {
    return apiClient.post('/serviceList', vehicle)
};

const requestServices = (vehicle) => {
    return apiClient.post('/serviceRequest', vehicle)
};

const listReminders = (vehicle) => {
    return apiClient.post('/reminders', vehicle)
};

const requestMaintenance = (vehicle) => {
    return apiClient.post('/maintenanceRequest', vehicle)
};

const requestClosingOffer = (vehicle) => {
    return apiClient.post('/closingOfferRequest', vehicle)
};

const requestNewVehicleOffer = (vehicle) => {
    return apiClient.post('/newVehicleOfferRequest', vehicle)
};

const getNews = () => {
    return apiClient.get('/newsList')
};

export default apiClient

export {
    getStatus,
    loginUser,
    changePassword,
    forgotPassword,
    tokenRefresh,
    logout,
    listVehicles,
    getVehicle,
    listVignettes,
    listMilages,
    setMilage,
    listService,
    requestServices,
    listReminders,
    requestMaintenance,
    requestClosingOffer,
    requestNewVehicleOffer,
    getNews,
    listContracts
}

<template>
	<div class="loginPage">
		<a-row type="flex" justify="space-around" align="middle" style="margin-top: 4em">
			<a-col :xl="{ span: 6 }" :lg="{ span: 8 }" :md="{ span: 8 }" :sm="{ span: 16 }" :xs="{ span: 20 }">
				<a-card :bordered="false">
					<img
						draggable="false"
						src="../assets/wigo-logo-white.png"
						width="80px"
					/>
					<h2>Bejelentkezés</h2>
					<div>
						<a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 25 }" @submit="handleSubmit">
							<a-form-item :validate-status="userNameError() ? 'error' : ''" :help="userNameError() || ''">
								<a-input
									v-decorator="[
										'userName',
										{
											rules: [
												{
													required: true,
													message: 'Kérjük adja meg felhasználó nevét!',
												},
											],
										},
									]"
									autocomplete="on"
									placeholder="Felhasználónév"
								>
									<a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
								</a-input>
							</a-form-item>
							<a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
								<a-input-password
									allow-clear
									v-decorator="[
										'password',
										{
											rules: [
												{
													required: true,
													message: 'Kérjük adja meg jelszavát',
												},
											],
										},
									]"
									type="password"
									autocomplete="on"
									placeholder="Jelszó"
								>
									<a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
								</a-input-password>
							</a-form-item>
							<a-form-item>
								<a-button
									class="loginButton"
									type="primary"
									html-type="submit"
									:disabled="hasErrors(form.getFieldsError())"
									block
								>
									Belépés
								</a-button>
							</a-form-item>
						</a-form>
					</div>

					<div
						:class="{ textBold: showForgetPassword }"
						class="forgot__password"
						@click="showForgetPassword = !showForgetPassword"
					>
						Elfelejtett jelszó
					</div>
					<div v-if="showForgetPassword">
						<a-divider>Új jelszó kérése</a-divider>
						<a-form :form="form2" @submit="changePassword">
							<a-form-item :validate-status="userError() ? 'error' : ''" :help="userError() || ''">
								<a-input
									v-decorator="[
										'user',
										{
											rules: [
												{
													required: true,
													message: 'Kérjük adja meg felhasználónevét!',
												},
											],
										},
									]"
									placeholder="Felhasználónév"
								>
									<a-icon slot="prefix" type="user" />
								</a-input>
							</a-form-item>
							<a-button type="primary" html-type="submit" block class="forgotPwButton">Küldés</a-button>
						</a-form>
					</div>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
// import { getStatus } from '@/services/EventService.js';
import Vue from 'vue';

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
export default {
	name: 'Login',
	data() {
		return {
			showForgetPassword: false,
			manager: true,
			client: false,
			hasErrors,
			form: this.$form.createForm(this),
			form2: this.$form.createForm(this),
		};
	},

	// created() {
	// 	getStatus().then((response) => {
	// 		console.log(response);
	// 	});
	// },
	mounted() {
		this.$nextTick(() => {
			// To disabled submit button at the beginning.
			this.form.validateFields();
			this.form2.validateFields();
		});
	},
	methods: {
		changePassword(e) {
			e.preventDefault();
			this.form2.validateFields((err, values) => {
				if (!err) {
					this.$store
						.dispatch('forgotPassword', {
							loginName: values.user,
						})
						.then(() => {
							this.$showNotification(
								'Sikeres',
								'Új jelszavát elküldtük a megadott e-mail címre!',
								6,
								<a-icon type="check-circle" style="color:#00ce55" />
							);
						})
						.catch(() => {
							this.$showNotification(
								'Hiba',
								'A jelszó módosítás nem sikerült. Kérjük, próbálja meg mégegyszer!',
								6,
								<a-icon type="close-circle" style="color:#fc0a33" />
							);
						});
				}
			});
		},
		// Only show error after a field is touched.
		userNameError() {
			const { getFieldError, isFieldTouched } = this.form;
			return isFieldTouched('userName') && getFieldError('userName');
		},

		passwordError() {
			const { getFieldError, isFieldTouched } = this.form;
			return isFieldTouched('password') && getFieldError('password');
		},
		userError() {
			const { getFieldError, isFieldTouched } = this.form2;
			return isFieldTouched('user') && getFieldError('user');
		},

		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$store
						.dispatch('login', {
							loginName: values.userName,
							password: values.password,
						})
						.then(() => {
							if (this.$store.state.auth.generatedPass === true && this.$store.state.auth.lastLoginTime == 0) {
								this.$router.push({ name: 'ChangePassword' });
							} else this.$router.push({ name: 'News' });

							const userEmail = localStorage.getItem('userEmail');

							Vue.prototype.$OneSignal = window.OneSignal || [];

							Vue.prototype.$OneSignal.push(function() {
								Vue.prototype.$OneSignal.init({
									appId: process.env.VUE_APP_ONESIGNAL_ID,
									safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_ID,
								});
								Vue.prototype.$OneSignal.showSlidedownPrompt();
								Vue.prototype.$OneSignal.setEmail(userEmail);
								// Vue.prototype.$OneSignal.setEmail("example@domain.com")
								//     .then(function (emailId) {
								//         // Callback called when email have finished sending
								//         console.log("emailId: ", emailId);
								//     });
								// Vue.prototype.$OneSignal.on('notificationDisplay', function (event) {
								//     console.log('OneSignal notification displayed:', event);
								// });
							});
						})
						.catch((err) => {
							if (err.response.data == '') {
								this.$showNotification(
									'Hálózati hiba',
									'Kérjük,vegye fel a kapcsolatot a webhely tulajdonosával',
									6,
									<a-icon type="close-circle" style="color:#fc0a33" />
								);
							} else {
								this.$showNotification(
									'Hiba',
									'Hibás felhasználónév vagy jelszó. Kérjük, gépelje be őket újra!',
									6,
									<a-icon type="close-circle" style="color:#fc0a33" />
								);
							}
						});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.ant-divider {
	color: #fff !important;
}
.loginPage > .anticon svg {
	color: black !important;
}
.forgot__password {
	text-align: center;
	color: #fff !important;
}
.forgot__password:hover {
	cursor: pointer;
}
.loginPage {
	margin-top: 10em;
}
.loginButton,
.forgotPwButton {
	color: #fff !important;
	background: #1f4e8d !important;
	border-color: #1f4e8d !important;
}

.loginButton {
	margin-bottom: 1em;
}

.ant-select-selection {
	padding-left: 20px !important;
}
.loginPage h2 {
	font-weight: 500;
	font-size: 2.5em;
	text-align: center;
	margin-bottom: 20px;
	color: #fff !important;
}
.ant-card {
	background: rgba(255, 255, 255, 0.33) !important;
	border-radius: 22px !important;
}
.ant-card-body div {
	margin-bottom: 15px;
}
.ant-card {
	box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 576px) {
	/* Target devices narrower than 578px. */
	.loginPage h2 {
		font-weight: 500;
		font-size: 1.5em;
		text-align: center;
		margin-bottom: 20px;
		color: #fff !important;
	}
	.loginPage {
		margin-top: 5em;
	}
}
</style>

import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App.vue';
import 'ant-design-vue/dist/antd.css';
import router from './router';
import store from './store';
import './shared/notification';
import VueApexCharts from 'vue-apexcharts'
import DownloadExcel from './shared/DownloadExcel.vue'
import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false;
Vue.component('apexchart', VueApexCharts)
Vue.component(DownloadExcel.name, DownloadExcel)
Vue.use(Antd);
Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        sm: 450,
        tablet: 900,
        md: 1500,
        lg: Infinity,
    }
});

// window.OneSignal = window.OneSignal || [];
// window.OneSignal.push(() => {
//     window.OneSignal.init({
//         appId: process.env.VUE_APP_ONESIGNAL_ID,
//     });
//     window.OneSignal.showSlidedownPrompt();
//     window.OneSignal.setEmail('example@domain.com');
//     window.OneSignal.on('notificationDisplay', function (event) {
//         console.log('OneSignal notification displayed:', event);
//     });
// });

Vue.filter('filterNumbers', function (value) {
    if (!value) return '';
    const value1 = new Intl.NumberFormat('hu-HU').format(value);
    return value1;
})

new Vue({
    router,
    store,
    created() {
        if (this.$route.name === 'Login') {
            document.body.style.backgroundColor = '#1f4e8d'
        } else {
            document.body.style.backgroundColor = '#06213B'
        }
        const userString = localStorage.getItem('userEmail')
        const tokenString = localStorage.getItem('token')
        const linkString = localStorage.getItem('link')
        const balanceString = localStorage.getItem('isBalanceGranted')

        if (userString && tokenString) {
            this.$store.commit('SET_TOKEN', tokenString)
            this.$store.commit('SET_USER_EMAIL', userString)
            this.$store.commit('SET_LINK', linkString)
            this.$store.commit('SET_BALANCE', balanceString)
        }
    },
    render: h => h(App)
}).$mount('#app');

<template>
	<div :id="idName" @click="generate">
		<slot> Download {{ name }} </slot>
	</div>
</template>

<script>
import download from 'browser-downloads';
import store from '../store';

export default {
	name: 'download-excel',
	props: {
		propName: {
			type: String,
		},
		// mime type [xls, csv]
		type: {
			type: String,
			default: 'xls',
		},
		// Json to download
		data: {
			type: Array,
			required: false,
			default: null,
		},
		// fields inside the Json Object that you want to export
		// if no given, all the properties in the Json are exported
		fields: {
			type: Object,
			required: false,
		},
		// this prop is used to fix the problem with other components that use the
		// variable fields, like vee-validate. exportFields works exactly like fields
		exportFields: {
			type: Object,
			required: false,
		},
		// Use as fallback when the row has no field values
		defaultValue: {
			type: String,
			required: false,
			default: '',
		},
		// Title(s) for the data, could be a string or an array of strings (multiple titles)
		title: {
			default: null,
		},
		// Footer(s) for the data, could be a string or an array of strings (multiple footers)
		footer: {
			default: null,
		},
		// filename to export
		name: {
			type: String,
			default: 'data.xls',
		},
		fetch: {
			type: Function,
		},
		meta: {
			type: Array,
			default: () => [],
		},
		worksheet: {
			type: String,
			default: 'Munka1',
		},
		//event before generate was called
		beforeGenerate: {
			type: Function,
		},
		//event before download pops up
		beforeFinish: {
			type: Function,
		},
	},
	computed: {
		// unique identifier
		idName() {
			var now = new Date().getTime();
			return 'export_' + now;
		},

		downloadFields() {
			if (this.fields !== undefined) return this.fields;
			if (this.exportFields !== undefined) return this.exportFields;

			return [];
		},
	},
	methods: {
		async generate() {
			let type = this.propName;
			if (type != 'VEHICLE' && type != 'CONTRACT' && type != 'SERVICE') {
				type = 'VEHICLE';
			}

			if (typeof this.beforeGenerate === 'function') {
				await this.beforeGenerate();
			}
			let data = this.data;
			if (typeof this.fetch === 'function' || !data) data = await this.fetch();

			if (!data || !data.length) {
				return;
			}

			let json = this.getProcessedJson(data, this.downloadFields);
			return this.export(this.jsonToCSV(json, type), this.name.replace('.xls', '.csv'), 'application/csv');
		},
		/*
                Use downloadjs to generate the download link
                */
		export: async function(data, filename, mime) {
			let blob = this.base64ToBlob(data, mime);
			if (typeof this.beforeFinish === 'function') await this.beforeFinish();
			download(blob, filename, mime);
		},
		/*
                jsonToCSV
                ---------------
                Transform json data into an CSV file.
				type: VEHICLE | CONTRACT
                */
		jsonToCSV(data, type) {
			var csvData = [],
				numRegExp = /^\d+(\.\d+)*$/,
				dateRegExp = /^\d{4}-\d{2}-\d{2}$/,
				valueStr,
				escapedCSV,
				index,
				key;
			//BOM - hogy az UTF-8-as karakterek is jol jelenjenek meg
			csvData.push('\ufeff');
			//Header
			if (this.title != null) {
				csvData.push(this.parseExtraData(this.title, '${data}\r\n'));
			}
			//Fields
			if (type == 'VEHICLE') {
				csvData.push('"rendszam"');
				csvData.push(';');
				csvData.push('"gyartmany"');
				csvData.push(';');
				csvData.push('"tipus"');
				csvData.push(';');
				csvData.push('"modell"');
				csvData.push(';');
				csvData.push('"motor"');
				csvData.push(';');
				csvData.push('"jarmutipusnev"');
				csvData.push(';');
				csvData.push('"hajtas"');
				csvData.push(';');
				csvData.push('"forgalmiengedelyszam"');
				csvData.push(';');
				csvData.push('"muszakiervenyessegvege"');
				csvData.push(';');
				csvData.push('"alvazszam"');
				csvData.push(';');
				csvData.push('"hengerurtartalom"');
				csvData.push(';');
				csvData.push('"teljesitmeny"');
				csvData.push(';');
				csvData.push('"szervizintervallumkm"');
				csvData.push(';');
				csvData.push('"szervizintervallumhonap"');
				csvData.push(';');
				csvData.push('"legnagyobboraallas"');
				csvData.push(';');
				csvData.push('"varhatoidoszakiszervizhonap"');
				csvData.push(';');
				csvData.push('"varhatoidoszakiszervizkm"');
				csvData.push(';');
				csvData.push('"autohasznalonev"');
				csvData.push(';');
				csvData.push('"autohasznalotelefon"');
				csvData.push(';');
				csvData.push('"autohasznaloemail"');
				csvData.push(';');
				csvData.push('"uzemeltetonev"');
				csvData.push(';');
				csvData.push('"uzemeltetotelefon"');
				csvData.push(';');
				csvData.push('"uzemeltetoemail"');
				csvData.push(';');
				csvData.push('"ertekesitonev"');
				csvData.push(';');
				csvData.push('"ertekesitotelefon"');
				csvData.push(';');
				csvData.push('"ertekesitoemail"');
				csvData.push(';');
			} else if (type == 'CONTRACT') {
				csvData.push('"szerzodesszam"');
				csvData.push(';');
				csvData.push('"elszamolasmodja"');
				csvData.push(';');
				csvData.push('"rendszam"');
				csvData.push(';');
				csvData.push('"gyartmany"');
				csvData.push(';');
				csvData.push('"tipus"');
				csvData.push(';');
				csvData.push('"modell"');
				csvData.push(';');
				csvData.push('"motor"');
				csvData.push(';');
				csvData.push('"jarmutipusnev"');
				csvData.push(';');
				csvData.push('"hajtas"');
				csvData.push(';');
				csvData.push('"forgalmiengedelyszam"');
				csvData.push(';');
				csvData.push('"muszakiervenyessegvege"');
				csvData.push(';');
				csvData.push('"alvazszam"');
				csvData.push(';');
				csvData.push('"hengerurtartalom"');
				csvData.push(';');
				csvData.push('"teljesitmeny"');
				csvData.push(';');
				csvData.push('"szervizintervallumkm"');
				csvData.push(';');
				csvData.push('"szervizintervallumhonap"');
				csvData.push(';');
				csvData.push('"legnagyobboraallas"');
				csvData.push(';');
				csvData.push('"varhatoidoszakiszervizhonap"');
				csvData.push(';');
				csvData.push('"varhatoidoszakiszervizkm"');
				csvData.push(';');
				csvData.push('"autohasznalonev"');
				csvData.push(';');
				csvData.push('"autohasznalotelefon"');
				csvData.push(';');
				csvData.push('"autohasznaloemail"');
				csvData.push(';');
				csvData.push('"uzemeltetonev"');
				csvData.push(';');
				csvData.push('"uzemeltetotelefon"');
				csvData.push(';');
				csvData.push('"uzemeltetoemail"');
				csvData.push(';');
				csvData.push('"ertekesitonev"');
				csvData.push(';');
				csvData.push('"ertekesitotelefon"');
				csvData.push(';');
				csvData.push('"ertekesitoemail"');
				csvData.push(';');
				csvData.push('"futamidokezdete"');
				csvData.push(';');
				csvData.push('"futamidohossza"');
				csvData.push(';');
				csvData.push('"futamidovege"');
				csvData.push(';');
				csvData.push('"szerzodesesfutasteljesitmeny"');
				csvData.push(';');
				csvData.push('"deviza"');
				csvData.push(';');
				csvData.push('"induloertek"');
				csvData.push(';');
				csvData.push('"dijosszesen"');
				csvData.push(';');
				csvData.push('"tartosberletdij"');
				csvData.push(';');
				csvData.push('"maradvanyertek"');
				csvData.push(';');
				csvData.push('"szolgaltatasosszesen"');
				csvData.push(';');
				csvData.push('"autopalyahasznalatdij"');
				csvData.push(';');
				csvData.push('"belfoldisegelyszolgalatdij"');
				csvData.push(';');
				csvData.push('"cascodij"');
				csvData.push(';');
				csvData.push('"cegautoadodij"');
				csvData.push(';');
				csvData.push('"cseregepjarmuszolgaltatasdij"');
				csvData.push(';');
				csvData.push('"flottamanagementdij"');
				csvData.push(';');
				csvData.push('"gapdij"');
				csvData.push(';');
				csvData.push('"gumiabroncsszolgaltatasdij"');
				csvData.push(';');
				csvData.push('"hozomviszemszolgaltatasdij"');
				csvData.push(';');
				csvData.push('"kgfbdij"');
				csvData.push(';');
				csvData.push('"kiterjesztettcascodij"');
				csvData.push(';');
				csvData.push('"parkolasszolgaltatasdij"');
				csvData.push(';');
				csvData.push('"szervizszolgaltatasdij"');
				csvData.push(';');
				csvData.push('"uzemanyagkartyadij"');
				csvData.push(';');
				csvData.push('"gepjarmuadodij"');
				csvData.push(';');
				csvData.push('"gpsdij"');
				csvData.push(';');
				csvData.push('"kulfoldisegelyszolgalatdij"');
				csvData.push(';');

				if (store.state.auth.visibleBalance) {
					csvData.push('"gumiegyenleg"');
					csvData.push(';');
					csvData.push('"szervizegyenleg"');
					csvData.push(';');
				}
			} else {
				for (let key in data[0]) {
					csvData.push('"' + key + '"');
					csvData.push(';');
				}
			}
			csvData.pop();
			csvData.push('\r\n');
			//Data

			data.map(function(item) {
				// if (item.elszamolasmodja === 'ZART_VEGU') {
				// 	Object.entries(item).forEach(entry => {
				// 		let [key, value] = entry;

				// 		if (value === '') {
				// 			//item.e[0] = 'kutya';
				// 			key;
				// 			entry[1] = 'Inaktív';
				// 		} else entry[1] = 'Aktív';
				// 		console.log(entry);
				// 		return entry;
				// 	});
				// }

				// Zárt végű
				if (item.elszamolasmodja === 'ZART_VEGU' && item.tartosberletdij == '') {
					item.tartosberletdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.tartosberletdij != '') {
					item.tartosberletdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU') {
					item.maradvanyertek = '';
				}

				if (item.elszamolasmodja === 'ZART_VEGU') {
					item.szolgaltatasosszesen = '';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.autopalyahasznalatdij == '') {
					item.autopalyahasznalatdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.autopalyahasznalatdij != '') {
					item.autopalyahasznalatdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.belfoldisegelyszolgalatdij == '') {
					item.belfoldisegelyszolgalatdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.belfoldisegelyszolgalatdij != '') {
					item.belfoldisegelyszolgalatdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.cascodij == '') {
					item.cascodij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.cascodij != '') {
					item.cascodij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.cegautoadodij == '') {
					item.cegautoadodij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.cegautoadodij != '') {
					item.cegautoadodij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.cseregepjarmuszolgaltatasdij == '') {
					item.cseregepjarmuszolgaltatasdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.cseregepjarmuszolgaltatasdij != '') {
					item.cseregepjarmuszolgaltatasdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.flottamanagementdij == '') {
					item.flottamanagementdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.flottamanagementdij != '') {
					item.flottamanagementdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.gapdij == '') {
					item.gapdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.gapdij != '') {
					item.gapdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.gumiabroncsszolgaltatasdij == '') {
					item.gumiabroncsszolgaltatasdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.gumiabroncsszolgaltatasdij != '') {
					item.gumiabroncsszolgaltatasdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.hozomviszemszolgaltatasdij == '') {
					item.hozomviszemszolgaltatasdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.hozomviszemszolgaltatasdij != '') {
					item.hozomviszemszolgaltatasdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.kgfbdij == '') {
					item.kgfbdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.kgfbdij != '') {
					item.kgfbdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.kiterjesztettcascodij == '') {
					item.kiterjesztettcascodij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.kiterjesztettcascodij != '') {
					item.kiterjesztettcascodij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.flottamanagementdij == '') {
					item.flottamanagementdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.flottamanagementdij != '') {
					item.flottamanagementdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.parkolasszolgaltatasdij == '') {
					item.parkolasszolgaltatasdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.parkolasszolgaltatasdij != '') {
					item.parkolasszolgaltatasdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.szervizszolgaltatasdij == '') {
					item.szervizszolgaltatasdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.szervizszolgaltatasdij != '') {
					item.szervizszolgaltatasdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.uzemanyagkartyadij == '') {
					item.uzemanyagkartyadij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.uzemanyagkartyadij != '') {
					item.uzemanyagkartyadij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.gepjarmuadodij == '') {
					item.gepjarmuadodij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.gepjarmuadodij != '') {
					item.gepjarmuadodij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.gpsdij == '') {
					item.gpsdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.gpsdij != '') {
					item.gpsdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU' && item.kulfoldisegelyszolgalatdij == '') {
					item.kulfoldisegelyszolgalatdij = 'Inaktív';
				} else if (item.elszamolasmodja === 'ZART_VEGU' && item.kulfoldisegelyszolgalatdij != '') {
					item.kulfoldisegelyszolgalatdij = 'Aktív';
				}

				if (item.elszamolasmodja === 'ZART_VEGU') {
					item.gumiegyenleg = '0';
					item.szervizegyenleg = '0';
				}

				// Nyilt végű
				if (item.elszamolasmodja === 'NYILT_VEGU' && item.tartosberletdij == '') {
					item.tartosberletdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.maradvanyertek == '') {
					item.maradvanyertek = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU') {
					item.szolgaltatasosszesen = String(Number(item.dijosszesen) - Number(item.tartosberletdij));
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.autopalyahasznalatdij == '') {
					item.autopalyahasznalatdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.belfoldisegelyszolgalatdij == '') {
					item.belfoldisegelyszolgalatdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.cascodij == '') {
					item.cascodij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.cegautoadodij == '') {
					item.cegautoadodij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.cseregepjarmuszolgaltatasdij == '') {
					item.cseregepjarmuszolgaltatasdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.flottamanagementdij == '') {
					item.flottamanagementdij = '0';
				}
				if (item.elszamolasmodja === 'NYILT_VEGU' && item.gapdij == '') {
					item.gapdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.gumiabroncsszolgaltatasdij == '') {
					item.gumiabroncsszolgaltatasdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.kgfbdij == '') {
					item.kgfbdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.parkolasszolgaltatasdij == '') {
					item.parkolasszolgaltatasdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.szervizszolgaltatasdij == '') {
					item.szervizszolgaltatasdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.uzemanyagkartyadij == '') {
					item.uzemanyagkartyadij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.gepjarmuadodij == '') {
					item.gepjarmuadodij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.gpsdij == '') {
					item.gpsdij = '0';
				}

				if (item.elszamolasmodja === 'NYILT_VEGU' && item.kulfoldisegelyszolgalatdij == '') {
					item.kulfoldisegelyszolgalatdij = '0';
				}

				// Ez legyen a végén!!!!!
				if (item.elszamolasmodja == 'ZART_VEGU') {
					item.elszamolasmodja = 'Zárt';
				} else {
					item.elszamolasmodja = 'Elszámolásos';
				}

				if (type == 'VEHICLE' || type == 'CONTRACT') {
					for (index = 0; index < (type == 'VEHICLE' ? 26 : store.state.auth.visibleBalance ? 57 : 38); index++) {
						if (type == 'VEHICLE') {
							switch (index) {
								case 0:
									key = 'rendszam';
									break;
								case 1:
									key = 'gyartmany';
									break;
								case 2:
									key = 'tipus';
									break;
								case 3:
									key = 'modell';
									break;
								case 4:
									key = 'motor';
									break;
								case 5:
									key = 'jarmutipusnev';
									break;
								case 6:
									key = 'hajtas';
									break;
								case 7:
									key = 'forgalmiengedelyszam';
									break;
								case 8:
									key = 'muszakiervenyessegvege';
									break;
								case 9:
									key = 'alvazszam';
									break;
								case 10:
									key = 'hengerurtartalom';
									break;
								case 11:
									key = 'teljesitmeny';
									break;
								case 12:
									key = 'szervizintervallumkm';
									break;
								case 13:
									key = 'szervizintervallumhonap';
									break;
								case 14:
									key = 'legnagyobboraallas';
									break;
								case 15:
									key = 'varhatoidoszakiszervizhonap';
									break;
								case 16:
									key = 'varhatoidoszakiszervizkm';
									break;
								case 17:
									key = 'autohasznalonev';
									break;
								case 18:
									key = 'autohasznalotelefon';
									break;
								case 19:
									key = 'autohasznaloemail';
									break;
								case 20:
									key = 'uzemeltetonev';
									break;
								case 21:
									key = 'uzemeltetotelefon';
									break;
								case 22:
									key = 'uzemeltetoemail';
									break;
								case 23:
									key = 'ertekesitonev';
									break;
								case 24:
									key = 'ertekesitotelefon';
									break;
								case 25:
									key = 'ertekesitoemail';
									break;
							}
						} else {
							switch (index) {
								case 0:
									key = 'szerzodesszam';
									break;
								case 1:
									key = 'elszamolasmodja';
									break;
								case 2:
									key = 'rendszam';
									break;
								case 3:
									key = 'gyartmany';
									break;
								case 4:
									key = 'tipus';
									break;
								case 5:
									key = 'modell';
									break;
								case 6:
									key = 'motor';
									break;
								case 7:
									key = 'jarmutipusnev';
									break;
								case 8:
									key = 'hajtas';
									break;
								case 9:
									key = 'forgalmiengedelyszam';
									break;
								case 10:
									key = 'muszakiervenyessegvege';
									break;
								case 11:
									key = 'alvazszam';
									break;
								case 12:
									key = 'hengerurtartalom';
									break;
								case 13:
									key = 'teljesitmeny';
									break;
								case 14:
									key = 'szervizintervallumkm';
									break;
								case 15:
									key = 'szervizintervallumhonap';
									break;
								case 16:
									key = 'legnagyobboraallas';
									break;
								case 17:
									key = 'varhatoidoszakiszervizhonap';
									break;
								case 18:
									key = 'varhatoidoszakiszervizkm';
									break;
								case 19:
									key = 'autohasznalonev';
									break;
								case 20:
									key = 'autohasznalotelefon';
									break;
								case 21:
									key = 'autohasznaloemail';
									break;
								case 22:
									key = 'uzemeltetonev';
									break;
								case 23:
									key = 'uzemeltetotelefon';
									break;
								case 24:
									key = 'uzemeltetoemail';
									break;
								case 25:
									key = 'ertekesitonev';
									break;
								case 26:
									key = 'ertekesitotelefon';
									break;
								case 27:
									key = 'ertekesitoemail';
									break;
								case 28:
									key = 'futamidokezdete';
									break;
								case 29:
									key = 'futamidohossza';
									break;
								case 30:
									key = 'futamidovege';
									break;
								case 31:
									key = 'szerzodesesfutasteljesitmeny';
									break;
								case 32:
									key = 'deviza';
									break;
								case 33:
									key = 'induloertek';
									break;
								case 34:
									key = 'dijosszesen';
									break;
								case 35:
									key = 'tartosberletdij';
									break;
								case 36:
									key = 'maradvanyertek';
									break;
								case 37:
									key = 'szolgaltatasosszesen';
									break;

								case 38:
									key = 'autopalyahasznalatdij';
									break;
								case 39:
									key = 'belfoldisegelyszolgalatdij';
									break;
								case 40:
									key = 'cascodij';
									break;
								case 41:
									key = 'cegautoadodij';
									break;
								case 42:
									key = 'cseregepjarmuszolgaltatasdij';
									break;
								case 43:
									key = 'flottamanagementdij';
									break;
								case 44:
									key = 'gapdij';
									break;
								case 45:
									key = 'gumiabroncsszolgaltatasdij';
									break;
								case 46:
									key = 'hozomviszemszolgaltatasdij';
									break;
								case 47:
									key = 'kgfbdij';
									break;
								case 48:
									key = 'kiterjesztettcascodij';
									break;
								case 49:
									key = 'parkolasszolgaltatasdij';
									break;
								case 50:
									key = 'szervizszolgaltatasdij';
									break;
								case 51:
									key = 'uzemanyagkartyadij';
									break;
								case 52:
									key = 'gepjarmuadodij';
									break;
								case 53:
									key = 'gpsdij';
									break;
								case 54:
									key = 'kulfoldisegelyszolgalatdij';
									break;
								case 55:
									key = 'gumiegyenleg';
									break;
								case 56:
									key = 'szervizegyenleg';
									break;
							}
						}

						valueStr = String(item[key]);

						if (!numRegExp.test(valueStr) && !dateRegExp.test(valueStr)) {
							escapedCSV = '="' + valueStr.replace(/"/g, '""') + '"';
						} else {
							escapedCSV = valueStr;
						}
						csvData.push(escapedCSV);
						csvData.push(';');
					}
				} else {
					for (key in item) {
						valueStr = String(item[key]);
						if (!numRegExp.test(valueStr) && !dateRegExp.test(valueStr)) {
							escapedCSV = '="' + valueStr.replace(/"/g, '""') + '"';
						} else {
							escapedCSV = valueStr;
						}
						csvData.push(escapedCSV);
						csvData.push(';');
					}
				}
				csvData.pop();
				csvData.push('\r\n');
			});
			//Footer
			if (this.footer != null) {
				csvData.push(this.parseExtraData(this.footer, '${data}\r\n'));
			}
			return csvData.join('');
		},
		/*
                getProcessedJson
                ---------------
                Get only the data to export, if no fields are set return all the data
                */
		getProcessedJson(data, header) {
			let keys = this.getKeys(data, header);
			let newData = [];
			let _self = this;
			data.map(function(item /*, index*/) {
				let newItem = {};
				for (let label in keys) {
					let property = keys[label];
					newItem[label] = _self.getValue(property, item);
				}
				newData.push(newItem);
			});

			return newData;
		},
		getKeys(data, header) {
			if (header.length) {
				return header;
			}

			let keys = {};
			for (let key in data[0]) {
				keys[key] = key;
			}
			return keys;
		},
		/*
                parseExtraData
                ---------------
                Parse title and footer attribute to the csv format
                */
		parseExtraData(extraData, format) {
			let parseData = '';
			if (Array.isArray(extraData)) {
				for (var i = 0; i < extraData.length; i++) {
					parseData += format.replace('${data}', extraData[i]);
				}
			} else {
				parseData += format.replace('${data}', extraData);
			}
			return parseData;
		},

		getValue(key, item) {
			const field = typeof key !== 'object' ? key : key.field;
			let indexes = typeof field !== 'string' ? [] : field.split('.');
			let value = this.defaultValue;

			if (!field) value = item;
			else if (indexes.length > 1) value = this.getValueFromNestedItem(item, indexes);
			else value = this.parseValue(item[field]);

			//if (key.hasOwnProperty('callback'))
			if (key.callback !== undefined) value = this.getValueFromCallback(value, key.callback);

			return value;
		},

		/*
            convert values with newline \n characters into <br/>
            */
		valueReformattedForMultilines(value) {
			if (typeof value == 'string') return value.replace(/\n/gi, '<br/>');
			else return value;
		},

		getValueFromNestedItem(item, indexes) {
			let nestedItem = item;
			for (let index of indexes) {
				if (nestedItem) nestedItem = nestedItem[index];
			}
			return this.parseValue(nestedItem);
		},

		getValueFromCallback(item, callback) {
			if (typeof callback !== 'function') return this.defaultValue;
			const value = callback(item);
			return this.parseValue(value);
		},
		parseValue(value) {
			return value || value === 0 || typeof value === 'boolean' ? value : this.defaultValue;
		},
		base64ToBlob(data, mime) {
			let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
			let bstr = atob(base64);
			let n = bstr.length;
			let u8arr = new Uint8ClampedArray(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		},
	}, // end methods
};
</script>

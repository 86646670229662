var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginPage"},[_c('a-row',{staticStyle:{"margin-top":"4em"},attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{attrs:{"xl":{ span: 6 },"lg":{ span: 8 },"md":{ span: 8 },"sm":{ span: 16 },"xs":{ span: 20 }}},[_c('a-card',{attrs:{"bordered":false}},[_c('img',{attrs:{"draggable":"false","src":require("../assets/wigo-logo-white.png"),"width":"80px"}}),_c('h2',[_vm._v("Bejelentkezés")]),_c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 25 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"validate-status":_vm.userNameError() ? 'error' : '',"help":_vm.userNameError() || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'userName',
									{
										rules: [
											{
												required: true,
												message: 'Kérjük adja meg felhasználó nevét!',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'userName',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Kérjük adja meg felhasználó nevét!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"on","placeholder":"Felhasználónév"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"validate-status":_vm.passwordError() ? 'error' : '',"help":_vm.passwordError() || ''}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{
										rules: [
											{
												required: true,
												message: 'Kérjük adja meg jelszavát',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Kérjük adja meg jelszavát',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"allow-clear":"","type":"password","autocomplete":"on","placeholder":"Jelszó"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"loginButton",attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"block":""}},[_vm._v(" Belépés ")])],1)],1)],1),_c('div',{staticClass:"forgot__password",class:{ textBold: _vm.showForgetPassword },on:{"click":function($event){_vm.showForgetPassword = !_vm.showForgetPassword}}},[_vm._v(" Elfelejtett jelszó ")]),(_vm.showForgetPassword)?_c('div',[_c('a-divider',[_vm._v("Új jelszó kérése")]),_c('a-form',{attrs:{"form":_vm.form2},on:{"submit":_vm.changePassword}},[_c('a-form-item',{attrs:{"validate-status":_vm.userError() ? 'error' : '',"help":_vm.userError() || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'user',
									{
										rules: [
											{
												required: true,
												message: 'Kérjük adja meg felhasználónevét!',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'user',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Kérjük adja meg felhasználónevét!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Felhasználónév"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-button',{staticClass:"forgotPwButton",attrs:{"type":"primary","html-type":"submit","block":""}},[_vm._v("Küldés")])],1)],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
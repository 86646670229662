<template>
	<div id="app">
		<template v-if="!isLoggedIn">
			<Login />
		</template>
		<template v-else>
			<Layout />
		</template>
	</div>
</template>

<script>
import Login from '@/layout/LoginPage';
import Layout from '@/layout/Index';
import { mapGetters } from 'vuex';
export default {
	name: 'App',
	components: {
		Login,
		Layout,
	},

	computed: mapGetters(['isLoggedIn']),
};
</script>

<style lang="scss">
#app {
	height: 100%;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 10px;
}
*::-webkit-scrollbar-track {
	background: white;
}
*::-webkit-scrollbar-thumb {
	background-color: #1f4e8d;
}
//@import '@/styles/_global.scss';
</style>

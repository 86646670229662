import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/layout/LoginPage.vue';


const Reports = () => import('@/views/Reports.vue');
const VehicleList = () => import('@/views/VehicleList.vue');
const Contracts = () => import('@/views/Contracts.vue');
const Administration = () => import('@/views/Administration.vue');
const ChangePassword = () => import('@/views/ChangePassword.vue');
const Reminders = () => import('@/views/Reminders.vue');
const News = () => import('@/views/News.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,

    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/vehicleList',
        name: 'VehicleList',
        component: VehicleList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contracts',
        name: 'Contracts',
        component: Contracts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/changePassword',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reminders',
        name: 'Reminders',
        component: Reminders,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/news',
        name: 'News',
        component: News,
        meta: {
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {
    const lastRouteName = localStorage.getItem("LS_ROUTE_KEY");
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({ name: "Login" });
        } else {
            next();
        }
    } else {
        if (localStorage.getItem("LS_ROUTE_KEY") == null) {
            next();
        } else {
            if (lastRouteName == 'Login' && from.name === null) {
                next()
            } else {
                next(lastRouteName);
            }
        }
    }
});

router.afterEach(to => {
    localStorage.setItem("LS_ROUTE_KEY", to.name);
});

export default router;

<template>
	<div>
		<a-layout id="components-layout-demo-fixed-sider" :style="layoutStyle">
			<a-layout-sider :trigger="null" collapsible v-model="collapsed" class="layoutSliderWeb">
				<div class="logo">
					<img
						draggable="false"
						v-if="collapsed"
						style="height: 23px ; margin: 14px 6px"
						src="../assets/wigo-logo-white.png"
					/>
					<img draggable="false" v-else style=" height: 44px ; margin: 10px 35px" src="../assets/wigo-logo-white.png" />
				</div>

				<div class="logoresponsive" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)">
					<img v-if="collapsed" style="height: 23px ; margin: 14px 6px" src="../assets/wigo-logo-white.png" />
					<img v-else style=" height: 44px ; margin: 10px 35px" src="../assets/wigo-logo-white.png" />
				</div>
				<a-menu mode="inline" :theme="theme" :defaultSelectedKeys="selectedMenu">
					<a-menu-item key="7" @click="selectMenuItem($event.key)">
						<a-icon type="global" />
						<router-link to="/news">
							<span>Aktualitások</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="2" @click="selectMenuItem($event.key)">
						<a-icon type="car" theme="filled" />
						<router-link to="/vehicleList">
							<span>Gépjármű adatok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="3" @click="selectMenuItem($event.key)" v-if="$store.state.auth.userType != 'AUTOHASZNALO'">
						<a-icon type="file-text" />
						<router-link to="/contracts">
							<span>Szerződések</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="4" @click="selectMenuItem($event.key)">
						<a-icon type="audit" />
						<router-link to="/administration">
							<span>Ügyintézés</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="5" @click="selectMenuItem($event.key)">
						<a-icon type="bell" />
						<router-link to="/reminders">
							<span>Emlékeztetők</span>
						</router-link>
					</a-menu-item>
					<a-menu-item v-if="isBalanceGranted" key="1" @click="selectMenuItem($event.key)">
						<a-icon type="bar-chart" />
						<router-link to="/reports">
							<span>Riportok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="6" @click="selectMenuItem($event.key)">
						<a-icon type="key" />
						<router-link to="/changePassword">
							<span>Jelszó módosítás</span>
						</router-link>
					</a-menu-item>
				</a-menu>
				<a-menu mode="inline" :theme="theme" :defaultSelectedKeys="selectedMenu" class="__menuresponsive">
					<a-menu-item key="7" @click="selectMenuItem($event.key)">
						<a-icon type="global" />
						<router-link to="/news">
							<span>Aktualitások</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="2" @click="selectMenuItem($event.key)">
						<a-icon type="car" theme="filled" />
						<router-link to="/vehicleList">
							<span>Gépjármű adatok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="3" @click="selectMenuItem($event.key)" v-if="$store.state.auth.userType != 'AUTOHASZNALO'">
						<a-icon type="file-text" />
						<router-link to="/contracts">
							<span>Szerződések</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="4" @click="selectMenuItem($event.key)">
						<a-icon type="audit" />
						<router-link to="/administration">
							<span>Ügyintézés</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="5" @click="selectMenuItem($event.key)">
						<a-icon type="bell" />
						<router-link to="/reminders">
							<span>Emlékeztetők</span>
						</router-link>
					</a-menu-item>
					<a-menu-item v-if="isBalanceGranted" key="1" @click="selectMenuItem($event.key)">
						<a-icon type="bar-chart" />
						<router-link to="/reports">
							<span>Riportok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="6" @click="selectMenuItem($event.key)">
						<a-icon type="key" />
						<router-link to="/changePassword">
							<span>Jelszó módosítás</span>
						</router-link>
					</a-menu-item>

					<a-menu-item v-if="collapsed" class="logoutresponsivecollapsed">
						<a-icon type="logout" @click="logout" />
						<p>Kijelentkezés</p>
					</a-menu-item>
					<a-menu-item v-else @click="logout" class="logoutresponsive"> <a-icon type="logout" />Kijelentkezés </a-menu-item>
				</a-menu>
			</a-layout-sider>
			<a-layout-sider :trigger="null" collapsible v-model="collapsedMenu" class="layoutSliderMobile">
				<div class="logo">
					<img
						draggable="false"
						v-if="collapsedMenu"
						style="height: 23px ; margin: 14px 6px"
						src="../assets/wigo-logo-white.png"
					/>
				</div>

				<div class="logoresponsive" :type="collapsedMenu">
					<img v-if="collapsedMenu" style="height: 23px ; margin: 14px 6px" src="../assets/wigo-logo-white.png" />
				</div>
				<a-menu mode="inline" :theme="theme" :defaultSelectedKeys="selectedMenu">
					<a-menu-item key="7" @click="selectMenuItem($event.key)">
						<a-icon type="global" />
						<router-link to="/news">
							<span>Aktualitások</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="2" @click="selectMenuItem($event.key)">
						<a-icon type="car" theme="filled" />
						<router-link to="/vehicleList">
							<span>Gépjármű adatok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="3" @click="selectMenuItem($event.key)" v-if="$store.state.auth.userType != 'AUTOHASZNALO'">
						<a-icon type="file-text" />
						<router-link to="/contracts">
							<span>Szerződések</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="4" @click="selectMenuItem($event.key)">
						<a-icon type="audit" />
						<router-link to="/administration">
							<span>Ügyintézés</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="5" @click="selectMenuItem($event.key)">
						<a-icon type="bell" />
						<router-link to="/reminders">
							<span>Emlékeztetők</span>
						</router-link>
					</a-menu-item>
					<a-menu-item v-if="isBalanceGranted" key="1" @click="selectMenuItem($event.key)">
						<a-icon type="bar-chart" />
						<router-link to="/reports">
							<span>Riportok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="6" @click="selectMenuItem($event.key)">
						<a-icon type="key" />
						<router-link to="/changePassword">
							<span>Jelszó módosítás</span>
						</router-link>
					</a-menu-item>
				</a-menu>
				<a-menu mode="inline" :theme="theme" :defaultSelectedKeys="selectedMenu" class="__menuresponsive">
					<a-menu-item key="7" @click="selectMenuItem($event.key)">
						<router-link to="/news"> <a-icon type="global" /><span>Aktualitások</span> </router-link>
					</a-menu-item>
					<a-menu-item key="2" @click="selectMenuItem($event.key)">
						<router-link to="/vehicleList">
							<a-icon type="car" theme="filled" /> <span>Gépjármű adatok</span>
						</router-link>
					</a-menu-item>
					<a-menu-item key="3" @click="selectMenuItem($event.key)" v-if="$store.state.auth.userType != 'AUTOHASZNALO'">
						<router-link to="/contracts"> <a-icon type="file-text" /> <span>Szerződések</span> </router-link>
					</a-menu-item>
					<a-menu-item key="4" @click="selectMenuItem($event.key)">
						<router-link to="/administration"> <a-icon type="audit" /> <span>Ügyintézés</span></router-link>
					</a-menu-item>
					<a-menu-item key="5" @click="selectMenuItem($event.key)">
						<router-link to="/reminders"><a-icon type="bell" /> <span>Emlékeztetők</span></router-link>
					</a-menu-item>
					<a-menu-item v-if="isBalanceGranted" key="1" @click="selectMenuItem($event.key)">
						<router-link to="/reports"><a-icon type="bar-chart" /><span>Riportok</span> </router-link>
					</a-menu-item>
					<a-menu-item key="6" @click="selectMenuItem($event.key)">
						<router-link to="/changePassword"> <a-icon type="key" /> <span>Jelszó módosítás</span></router-link>
					</a-menu-item>

					<a-menu-item v-if="collapsed" class="logoutresponsivecollapsed">
						<a-icon type="logout" @click="logout" />
						<p>Kijelentkezés</p>
					</a-menu-item>
					<a-menu-item v-else @click="logout" class="logoutresponsive"> <a-icon type="logout" /> </a-menu-item>
				</a-menu>
			</a-layout-sider>
			<a-layout>
				<a-layout-header style="padding: 0">
					<a-row>
						<a-col :xl="{ span: 8 }" :lg="{ span: 7 }" :md="{ span: 3 }" :sm="{ span: 3 }" :xs="{ span: 15 }">
							<a-icon
								class="trigger"
								style="color: white;"
								:type="collapsed ? 'menu-unfold' : 'menu-fold'"
								@click="() => (collapsed = !collapsed)"
							/>
						</a-col>
						<a-col
							:xl="{ span: 15, offset: 1 }"
							:lg="{ span: 15, offset: 1 }"
							:md="{ span: 20, offset: 1 }"
							:sm="{ span: 20, offset: 0 }"
							:xs="{ span: 20, offset: 2 }"
						>
							<div class="top__controller">
								<div class="group__controller">
									<span class="__email">{{ userName }} </span>
									<span class="__email userPartnerName"> | </span>
									<span class="__email userPartnerName">{{ userPartnerName }} </span>
								</div>
								<div class="group__controller divider"></div>
								<div class="group__controller">
									<a-button class="logout" type="primary" @click="logout" icon="logout">Kijelentkezés</a-button>
								</div>
							</div>
						</a-col>
					</a-row>
				</a-layout-header>
				<a-layout-content :style="{ minHeight: 'max-content' }">
					<div class="transbox" :class="[currentRouteName]">
						<router-view></router-view>
					</div>
				</a-layout-content>
			</a-layout>
		</a-layout>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
	data() {
		return {
			theme: 'dark',
			selectedMenu: ['1'],
			collapsed: false,
			collapsedMenu: true,
			layoutStyle: {
				height: window.innerHeight + 'px',
			},
		};
	},

	computed: {
		...mapState({ userName: (state) => state.auth.userName, userPartnerName: (state) => state.auth.userPartnerName }),
		...mapGetters(['isBalanceGranted']),
		currentRouteName() {
			return this.$route.name
		}
	},

	methods: {
		...mapActions(['logout']),
		selectMenuItem(e) {
			localStorage.setItem('active-menu', JSON.stringify([e]));
		},
	},
	created() {
		if (localStorage.getItem('active-menu') === null) {
			this.selectedMenu = ['7'];
		} else {
			this.selectedMenu = JSON.parse(localStorage.getItem('active-menu'));
		}
	},
};
</script>

<style lang="scss">
// @import "@/styles/_layout.scss";
.layoutSliderMobile {
	display: none;
}
h2 {
	font-weight: 500;
	font-size: 2.5em;
	text-align: center;
	color: #1f4e8d !important;
}

.__menuresponsive {
	display: none;
}

.logoresponsive {
	display: none;
}

.transbox {
	margin: 30px;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.75) !important;
}


.ant-layout {
	height: 100vh !important;
	background-color: #06213b !important;
	/* background-image: url('../assets/4k.png') !important;
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
	background-attachment: fixed !important; */
}

.ant-layout-sider {
	background: #1f4e8d !important;
	position: relative;
}

.ant-layout-sider-children ul {
	background: #1f4e8d !important;
}

.ant-layout-sider-children li a {
	color: #fff !important;
}

#components-layout-demo-fixed-sider .logo {
	height: 60px;

	background-color: #1f4e8d;
}

.ant-menu-item-selected {
	background: #6d8cb5 !important;
}

.logout {
	background: #1f4e8d!important;
	border-color: #1f4e8d!important;
	margin-right: 10px;
}

.logoutresponsive {
	background: #1f4e8d!important;
	border-color: #1f4e8d!important;
	margin-right: 10px;
}

.logoutresponsivecollapsed {
	background: #92cbc5 !important;
	border-color: #92cbc5 !important;
}

.ant-layout-header {
	background: #6d8cb5 !important;
}

// .anticon svg {
//   color: #fff !important;
// }

.__email {
	color: #fff !important;
}

.__email {
	margin-right: 0;
	color: #797979;
}
.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}
.top__controller {
	display: inline-block;
	float: right;
}
.d-contents {
	display: contents;
}
.group__controller {
	display: inline-block;
}
.ant-avatar {
	background-color: #1890ff !important;
}

.divider {
	border-left: 1px solid #bfbfbf;
	position: relative;
	top: -0.06em;
	height: 33px;
	margin: 0 15px;
	vertical-align: middle;
}

.ant-menu-item > a {
	display: inline !important;
	color: rgba(0, 0, 0, 0.65);
}

.ant-menu-inline-collapsed > .ant-menu-item span {
	display: none !important;
}
@media only screen and (max-width: 700px) {
	.userPartnerName {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.layoutSliderWeb {
		display: none;
	}

	.layoutSliderMobile {
		display: block;
	}
	.ant-menu li:last-child {
		position: absolute;
		bottom: 0;
		max-width: 100%;
		margin-bottom: 0px;
	}
	.logout {
		display: none !important;
	}
	.logo {
		display: none;
	}
	.logoresponsive {
		display: inline;
	}

	.group__controller.divider {
		display: none;
	}
	.trigger {
		display: none !important;
	}
	.ant-menu {
		display: none;
	}
	.__menuresponsive {
		display: inline;
	}
}
</style>
